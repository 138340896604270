export const SOURCE_DEPOSIT = [
    { id: 'DEPOSIT_ADMIN', name: 'Recargas por administrador' },
    { id: 'DEPOSIT_TRANSBANK', name: 'Recargas por transbank' },
    { id: 'DEPOSIT_STRIPE', name: 'Recargas por stripe' },
    { id: 'FINTOC', name: 'Recargas por fintoc' },
];

export const SOURCES = [
    { id: '0', name: 'web' },
    { id: '1', name: 'ios' },
    { id: '2', name: 'android' },
    { id: '3', name: 'blockchain' },
    { id: '4', name: 'consola' },
    { id: '5', name: 'admin' },
    { id: '6', name: 'business' },
    { id: '7', name: 'red bancaria' },
    { id: '8', name: 'lotes (vita)' },
    { id: '9', name: 'tarjeta' },
    { id: '10', name: 'ajustes de tarjetas' }
];

export const CATEGORIES = [
    { id: '0', name: 'Envios' },
    { id: '1', name: 'Recibidos' },
    { id: '2', name: 'Recargas' },
    { id: '3', name: 'Retiros' },
    { id: '4', name: 'Intercambios' },
    { id: '5', name: 'Comisión' },
    { id: '6', name: 'Pagos' },
    { id: '7', name: 'Transferencias internacionales' },
    { id: '9', name: 'Impuestos' },
    { id: '11', name: 'Pago de servicio' },
    { id: '13', name: 'Ajustes de credito para tarjetas' },
    { id: '14', name: 'Ajustes de debito para tarjetas' },
    { id: '15', name: 'Tarjetas' },
    { id: '16', name: 'Cargos por solicitud' },
    { id: '17', name: 'Mantención de tarjeta' },
];

export const STATUS = [
    { id: '1', name: 'Completado' },
    { id: '2', name: 'Pendiente' },
    { id: '3', name: 'Rechazado' },
    { id: '4', name: 'Procesado' },
    { id: '7', name: 'Revisando' },
];

export const EXTERNAL_PROVIDERS = [
    { id: '0', name: 'Dlocal' },
    { id: '1', name: 'Wyre' },
    { id: '2', name: 'Ripple' },
    { id: '3', name: 'Manual' },
    { id: '4', name: 'Powwi' },
    { id: '5', name: 'Fintoc' },
    { id: '6', name: 'Transbank' },
    { id: '7', name: 'Floyd' },
    { id: '8', name: 'Circle' },
    { id: '11', name: 'Bancolombia' },
    { id: '15', name: 'Wompi' },
    { id: '12', name: 'Floyd Heroes' },
    { id: '13', name: 'BIND' },
    { id: '14', name: 'Binance pay' },
    { id: '16', name: 'Fortress' },
    { id: '17', name: 'Nium' },
    { id: '18', name: 'STP' },
    { id: '19', name: 'Sandya' },
    { id: '20', name: 'DFNS' },
    { id: '21', name: 'BCI' },
    { id: '22', name: 'Khipu' },
    { id: '23', name: 'Pagacel' },
    { id: '24', name: 'Skrill' },
    { id: '25', name: 'Alfin Banco' },
    { id: '26', name: 'Pomelo' },
    { id: '27', name: 'Rendimento' },
    { id: '28', name: 'Bridge' },
    { id: '29', name: 'Transferswap' },
    { id: '30', name: 'B89' },
].sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);

export const CURRENCIES = [
    { id: '0', name: 'eth' },
    { id: '1', name: 'btc' },
    { id: '2', name: 'clp' },
    { id: '3', name: 'usd' },
    { id: '4', name: 'cop' },
    { id: '5', name: 'usdt' },
    { id: '6', name: 'usdc' },
    { id: '7', name: 'ars' },
    { id: '8', name: 'mxn' }
];

export const CRYPTO_CURRENCIES = [
    { id: '0', name: 'eth' },
    { id: '1', name: 'btc' },
    { id: '5', name: 'usdt' },
    { id: '6', name: 'usdc' },
];
